import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import App from './App'

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {
    background-color: #1F1F1F;
    color: #F1F1F1;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
  }

  a {
    color: #F1F1F1;
  }
`

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
