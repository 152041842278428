import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  width: calc(100% - 30px);
`

const Title = styled.h1`
  font-size: 2.3rem;
  letter-spacing: 0.1ex;
`

const Field = styled.label`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
`

const Box = styled.div`
  display: flex;
  margin: 15px 0;

  > * {
    height: 100%;
    max-height: 250px;
  }

  > * + * {
    margin-left: 15px;
  }

  canvas {
    min-height: 250px;
  }
`

const Button = styled.button`
  align-self: flex-end;
  padding: 5px 10px;
`

interface BackgroundProps {
  bgColor: string;
}

const Background = styled.div<BackgroundProps>`
  background-color: ${props => props.bgColor};
  padding-top: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const getColor = (bgColor: string) => {
  const rgb = parseInt(bgColor.substr(1), 16)
  const red = (rgb >> 16) & 0xff
  const green = (rgb >> 8) & 0xff
  const blue = (rgb >> 0) & 0xff
  const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue
  return Math.round(luma) > 128 ? '#000000' : '#FFFFFF'
}

const SwatchContainer = styled.div`
    position: relative;
    height: 250px;
    width: 250px;
  `
  const Name = styled.p<BackgroundProps>`
    color: ${props => getColor(props.bgColor)};
    left: 0;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 1;
  `

const Swatch = ({ bgColor }: BackgroundProps) => {
  return (
    <SwatchContainer>
      <Background bgColor={bgColor} />
      <Name bgColor={bgColor}>{bgColor}</Name>
    </SwatchContainer>
  )
}

interface ColorObject {
  red: number;
  green: number;
  blue: number;
}

const App = () => {
  const [image, setImage] = useState('')
  const [color, setColor] = useState('')
  const [blob, setBlob] = useState(new Blob())

  const handleImage = (files: FileList | null) => {
    if (files === null) return
    const file = files[0]
    const reader = new FileReader()
    reader.onload = image => { setImage(String(image.target?.result)) }
    reader.readAsDataURL(file)
  }

  const getHex = (color: number): string => color.toString(16).padStart(2, '0')

  const imageOnLoad = useCallback((canvas: HTMLCanvasElement, image: HTMLImageElement) => (): void => {
    const context = canvas.getContext('2d')
    const height = canvas.height = Math.ceil(image.naturalHeight)
    const width = canvas.width = Math.ceil(image.naturalWidth)
    context?.drawImage(image, 0, 0, width, height)

    let arrBuffer;
    const reader = new FileReader()
    reader.onload = async file => arrBuffer = await file.target?.result
    reader.readAsArrayBuffer(blob)

    console.time('proccessImageData')
    const imageData = context?.getImageData(0, 0, width, height)
    const data = imageData?.data
    console.log({ arrBuffer, blob, imageData })
    const length = data?.length || 0
    const rgb: ColorObject = {red: 0,green: 0, blue: 0}
    for (let i = 0; i < length; i += 4) {
      rgb.red += data ? data[i] : 0
      rgb.green += data ? data[i + 1] : 0
      rgb.blue += data ? data[i + 2] : 0
    }

    const pixels = length/4 // data is set as rgba, so divide by 4 to get amount of pixels
    const color = [
      getHex(~~(rgb.red/(pixels))),
      getHex(~~(rgb.green/(pixels))),
      getHex(~~(rgb.blue/(pixels))),
    ]
    console.timeEnd('proccessImageData')
    setColor(`#${color.join('')}`)
  }, [blob])

  const getRandomImage = async () => {
    const url = 'https://picsum.photos/250'
    const file = await fetch(url)
    const fileBlob = await file.blob()
    const reader = new FileReader()
    reader.onload = image => { setImage(String(image.target?.result)) }
    reader.readAsDataURL(fileBlob)
    setBlob(fileBlob)
  }

  const getColorSampling = useCallback((base64: string) => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    const image = new Image()
    image.onload = imageOnLoad(canvas, image)
    image.src = base64
  }, [imageOnLoad])

  useEffect(() => {
    image && getColorSampling(image)
  }, [image, getColorSampling])

  return (
    <Container>
      <Title>Image color sampling</Title>
      <p>
        You can get some image for testing on: <a href="http://unsplash.com" target="_blank" rel="noopener noreferrer">unsplash</a>
      </p>

      <Box>
        <Field>
          <span>Image to sample</span>
          <input
            type='file'
            name='image'
            onChange={(e) => handleImage(e.target.files)}
            accept='image/*'
          />
        </Field>
        <Button onClick={getRandomImage}>Get random</Button>
      </Box>
      <Box>
        {color && <Swatch bgColor={color} />}
        <canvas height="0" width="0" id="canvas" />
      </Box>
    </Container>
  )
}

export default App
